<template>
	<el-dialog top="1vh" width="80%" height='px' :visible.sync="is_show_in_Page">
    <el-button type="primary"  @click="toGetPdf(1,0)">下载</el-button>
    <div class="page">
    <!-- 税务上报模板 -->
    <div style="display:flex;">
		<div class="contract-container"  ref="content">
            <div class="head">运输协议</div>
            <div class="content">
                <div>甲方(托运人):</div><span>安徽省骏功物联网科技有限公司</span>
            </div>
             <div class="content">
                <div>乙方(承运人):</div><span>{{driveragreement_data.name}}</span>
            </div>
            <div class="content">
                <div>联系电话:</div><span>{{driveragreement_data.tel}}</span>
            </div>
             <div class="content">
                <div>身份证号:</div><span>{{driveragreement_data.id_card_num}}</span>
            </div>
            <div class="content">
                <div>车牌号/车型:</div><span>{{driveragreement_data.truck_plate_num}} / {{driveragreement_data.truck_type_name}}</span>
            </div>
            <div class="content">
                <div>开户银行:</div><span>{{driveragreement_data.bank_cname?driveragreement_data.bank_cname:''}}</span>
            </div>
             <div class="content">
                <div>账号:</div><span>{{driveragreement_data.card_num?driveragreement_data.card_num:''}}</span>
            </div>
            <div class="maks">
                <div>甲乙双方经友好协商，依照《中华人民共和国合同法》及其他国家现行法律法规及规范性文件之规定，经友好协商，就乙方为甲方提供货物运输服务签订本协议，以期共同遵守:</div>
            </div>
            <div class="content">
                <div>1.起运地:</div><span>{{driveragreement_data.case_adr}}</span>
            </div>
            <div class="content">
                <div>目的地:</div><span>{{driveragreement_data.aim_adr}}</span>
            </div>
             <div class="content">
                <div style="width:200px;margin:0">3.甲方支付乙方的总运费为:</div><span style="width:200px;margin-left:10px;margin-right:10px">{{driveragreement_data.give_carrier_total}}</span>元
            </div>
            <div class="content">
                <div style="width:535px;margin:0;"><span style="font-weight:800;margin:0;margin-right:-10px;border:none">备注：</span> ①总运费包括但不限于路桥费、燃油、折旧、人工等费用，前述各运费组成部分为暂定，以甲方实际支付为准，但总运费不变；②如乙方给甲方开具国家规定的《公路、内河货物运输业统一发票》或《货物运输业增值税专用发票》，则不扣减代开发票费用；如若乙方无法开具相应发票至甲方，甲方将委托第三方服务机构办理运输业务代开发票及缴纳税费，代开发票产生的税额和费用由乙方承担。如乙方不接受甲方提供的油卡且不能提供前述发票，则乙方同意由甲方代为开具前述发票。
                </div>
            </div>
             <div class="content">
                <div style="width:535px;margin:0;">4.甲乙双方权利义务</div>
            </div>
            <div class="content">
                <div style="width:535px;margin:0;">4.1 甲方要求乙方在行驶过程必须开启车辆北斗和GPS，甲方有权通过平台调阅乙方车辆 GPS 轨迹，依据 GPS 轨迹减免对乙方的时效扣款；；</div>
            </div>
            <div class="content">
                <div style="width:535px;margin:0;">4.2 甲方客户回单、货物清单、过磅单等标注承运货物名称、件数、重量及体积等信息，货物总价以每票货物实际价值的总和为准。</div>
                 </div>
            <div class="content">
                <div style="width:535px;margin:0;">4.3 乙方运输相关资质问题导致的罚款由乙方承担，给甲方造成损失的，应赔偿甲方的全部损失；
                </div>
            </div>
            <div class="content">
                <div style="width:535px;margin:0;">4.4 乙方应遵守甲方及甲方客户的所有管理安排、考核制度等所有内容。甲方客户对甲方的罚款由乙方承担，甲方有权在尾款中扣除。
                </div>
            </div>
            <div class="content">
                <div style="width:535px;margin:0;">4.5 未经甲方同意禁止乙方在途中更换车辆，否则，所有损失由乙方承担，给甲方造成损失的，还应赔偿甲方的全部损失；因不可抗力导致的车辆途中维修、事故、堵车、恶劣天气等，需向甲方及时报备影响时间，便于甲方予以减免因此产生的时效罚款；</div>
            </div>
            <div class="content">
                <div style="width:535px;margin:0;">4.6.乙方应提供安全合法的车辆以及司乘人员并按法律要求缴纳人员/车辆保险和货物险，道路运输过程中发生交通事故以及其他事故的，由乙方承担全部责任，与甲方无关；同时，因乙方责任导致现场处理的，乙方承担甲方往返差旅费用；
            </div>
            </div>
            <div class="content">
            <div style="width:535px;margin:0;">4.7 甲方交货后，货物毁损灭失的风险由乙方承担；乙方伪造或变造客户签收回单的，乙方应赔偿全部损失。</div>
            </div>
            
            <div class="content">
            <div style="width:535px;margin:0;">4.8 任何情况下禁止乙方扣留甲方托运货物，否则，乙方应赔偿甲方全部损失，甲方有权解除合同。
            </div>
            </div>
             <div class="content">
                <div style="width:535px;margin:0;">4.9 乙方运费是由甲方支付且已经包含了通行费费用，故乙方同意将运输过程中产生的通行费票据交予甲方，并同意票据可由甲方委托第三方直接开具。</div>
                 </div>
            <div class="content">
                <div style="width:535px;margin:0;">4.10 乙方违约的，甲方还有权不予支付剩余尾款，要求乙方承担人民币10万元的违约金。
                </div>
            </div>
             <div class="content">
                <div style="width:535px;margin:0;">4.11 甲方的全部损失包括但不限于甲方客户对甲方的罚款、对第三方的赔偿、差旅费、诉讼费、律师费、调查费、鉴定费、保全费等。
                </div>
            </div>
            <div class="content">
                <div style="width:535px;margin:0;margin-top:40px"><span style="font-weight:800;margin:0;margin-right:-10px;border:none">6.尾款确认：</span>①回单作为尾款结算凭证，运输到达时间截止 3 日内回单原件需寄
                给甲方；②油卡可按照实际消费情况寄回，油卡余额为零后 7 日内未寄回，则从尾款中扣除油卡押金；③甲方为乙方提供加油服务，在运费中予以扣除，加油记录可在 APP 内查询。</div>
            </div>
            <div class="content">
                <div style="width:535px;margin:0;">7.如有争议由甲乙双方协商解决，协商不成的提交甲方所在地法院诉讼；</div>
            </div>
            <div class="content">
                <div style="width:535px;margin:0;">8.本协议一式三份，甲方持两份、乙方持一份，具同等效力。
                </div>
            </div>
             <div class="content">
                <div style="width:535px;margin:0;font-weight:800;">9.本协议在甲方实现永久电子存档，乙方明确以上条款后请在APP端或小程序进行同意确认后生效。
                </div>
            </div>
            <div class="content" style="margin-top:70px;margin-bottom:150px;">
                <div style="width:600px;margin:0;"><span style="margin-right:200px;margin-left:-5px;border:0;">甲方（盖章）</span><img class="img"  src="../../../../res/img/zhang.png" alt=""><span style="border:0;margin-left:50px">乙方（签字或盖章）</span><span style="border:0;font-size:30px;font-family:'微软雅黑'">{{driveragreement_data.name}}</span>
                </div>
            </div>
		</div>
	</div>
  </div>
	</el-dialog>
</template>
<script>
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
	export default {
		props:{
			is_show:Number,//是否显示
			data:[Object,String],//运单详情
		},
		data() {
			return {
				//列表是否显示
				is_show_in_Page:false,
                driveragreement_data:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开页面
					this.is_show_in_Page=true;
                    this.get_page_data()
				}
			},
		},
		methods: {
            //协议下载
            toGetPdf(){
                let name = this.driveragreement_data.name + '-'+this.driveragreement_data.truck_plate_num
                this.getPdf(name)
            },
            getPdf(titile){
                var content = this.$refs['content']
                var scrollHeight = content.scrollHeight
                    var scrollWidth = content.scrollWidth
                html2canvas(content,{
                    allowTaint:true,
                }).then(canvas=>{
                    //内容的宽度
                    let contentWidth =scrollWidth;
                    //内容的高度
                    let contentHeight =scrollHeight;
                    //一页pdf显示htm1页面生成的canvas高度，a4纸的尺寸[595.28,841.89];
                    let pageHeight =contentWidth / 592.28 * 841.89;
                    //未生成pdf的htm1页面高度
                    let leftHeight = contentHeight
                    //页面偏移
                    let position = 0;
                    //a4纸的尺寸[595.28,841.89],htm1页面生成的canvas在pdf中图片的宽高
                    let imgwidth=620;
                    let imgHeight =792.28 / contentWidth * contentHeight
                    //canvas转图片数据
                    let pageData = canvas.toDataURL('img/jpeg',1.0);
                    //新建JSPDF对象
                    let PDF = new JsPDF('','pt', 'a4')
                    //判断是否分页
                    if(leftHeight<pageHeight){
                    PDF.addImage(pageData, 'JPEG',0,0,imgwidth,imgHeight)
                    }else{
                    while(leftHeight>0){
                        PDF.addImage(pageData, 'JPEG',0,position,imgwidth,imgHeight);
                        leftHeight -= pageHeight;
                        position -= 84189;
                        if(leftHeight>0){
                        PDF.addPage()
                        }
                    }
                    }
                    PDF.save(titile)
                })
            },
            get_page_data(){
                if(this.data.truck_tord_num){
                    this.$my.net.req({
                        data:{
                            mod:'res',
                            ctr:'driveragreement_api',
                            truck_tord_num_act:this.data.truck_tord_num
                        },callback:(data)=>{
                            this.driveragreement_data = data
                        }
                    })
                }else{
                    this.$my.net.req({
                        data:{
                            mod:'res',
                            ctr:'driveragreement_api',
                            bl_num:this.data.bl_num
                        },callback:(data)=>{
                            this.driveragreement_data = data
                        }
                    })
                }
            }
		}
	}
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // height: calc(100% - 40px);
  overflow: auto;
}
.contract-container{
    margin-top: 30px;
    margin: auto auto;
    width:2000px;
    color: #000;;
    padding-left: 25%;
    height: 1600px;
    // text-align: center;
    .head{
    margin-top: 40px;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 50px;
    margin-left: 320px;
    }
    .content{
        margin-left: 150px;
        display: flex;
        margin-bottom: 4px;
        div{
            width: 100px;
        }
        span{
            width: 400px;
            margin-left: 10px;
            border-bottom: 1px solid black;
        }
    }
    .maks{
        text-align: left;
        margin-left: 150px;
        width: 500px;
    }
}
.img{
  width: 140px;
  margin-left: -180px;
  position:relative;
  bottom: -50px;
}
</style>